@import '../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../node_modules/bootstrap/scss/functions'
@import '../../node_modules/bootstrap/scss/variables'
@import '../../node_modules/bootstrap/scss/mixins'

.yoSubNav
  padding: 0
  @include media-breakpoint-down(lg)
    \:global(.navbar-collapse)
      background-color: $gray-800
    \:global(.navbar-toggler)
      margin-right: 0
      margin-left: auto
  \:global(.navbar-collapse)
    margin-top: 0
    @include media-breakpoint-down(lg)
      padding: 20px
      \:global(.dropdown-menu)
        margin-top: 5px
        background-color: $gray-100
        box-shadow: $box-shadow-sm
  \:global(.navbar-nav)
    width: 100%
    \:global(.nav-item)
      margin-right: 3px
  @include media-breakpoint-down(lg)
    \:global(.btn-group)
      \:global(.btn)
        border-width: 1px !important
    \:global(.btn)
      border-width: 0
      background-color: transparent !important
      color: $gray-400 !important
      &.active
        color: $white
      i
        margin-right: 10px

    \:global(.nav-item)
      &:first-child
        margin-top: 0
        border-top: 0
        padding-top: 0
      width: 100%
      border-top: 1px solid $gray-700
      padding: 2.5px 0
      margin-left: 0 !important
      margin-right: auto !important

      \:global(.dropdown)
        position: relative
        \:global(.dropdown-menu)
          position: absolute
          left: 0
          text-overflow: ellipsis
          overflow-x: hidden
          word-break: break-all
          overflow-y: scroll

