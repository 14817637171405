@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.categoryCard
  \:global(.card-title)
    @media screen and (max-width: $mobileActive)
      padding-left: 25px !important
      position: relative
      &:before
        position: absolute
        top: 0
        left: 0
        font-family: 'Font Awesome 5 Free'
        font-weight: 900
        content: "\f07b"
        color: $gray-700
  \:global(.badge)
    margin-top: 8px

  @media screen and (min-width: $mobileActive)
    perspective: 500px
    top: 12px
    transition: all 300ms ease
    border-top-left-radius: 0 !important
    border-top-right-radius: 7px !important
    background-color: $white
    box-shadow: $box-shadow-sm !important
    height: calc(100% - 25px) !important
    padding-top: 10px
    width: 200px
    &:hover
      .category-card-inside
        transform: rotate(-7deg) translateY(-15%)
      ::after
        transform: rotateX(-15deg)
    \:global(.card-body)
      z-index: 100
      margin-top: 20px
      height: 160px !important
      padding: 0 !important
      \:global(.card-title)
        font-size: 1.15rem!important
      \:global(.card-text)
        font-size: 12px !important
      a
        align-items: center
        justify-content: center
        display: flex
        flex-direction: column
        padding: 10px
        overflow: hidden
        width: 100%
        height: 100%

    \:global(.card-footer)
      z-index: 100
    &::before
      width: 80px
      height: 25px
      content: ''
      background: $white
      position: absolute
      top: -25px
      left: -1px
      border-top-left-radius: 7px
      border-top-right-radius: 20px
      border: 1px solid rgba(0, 0, 0, 0.125)
      border-bottom: 0
    &::after
      width: 103%
      height: 86%
      position: absolute
      content: ''
      background: $white
      top: 30px
      left: -1.5%
      box-shadow: $box-shadow
      border: 1px solid rgba(0, 0, 0, 0.125) !important
      border-top-left-radius: 5px
      border-top-right-radius: 5px
      -webkit-transform: rotateX(-10deg)
      transform: rotateX(-10deg)
      transition: all 400ms ease
      -webkit-transition: all 400ms ease
      border-bottom-left-radius: 20px !important
      border-bottom-right-radius: 20px !important
      -webkit-transform: translate3d(0,0,0)
    .categoryCardInside
      width: 95%
      height: 80%
      position: absolute
      background: tint-color($primary, 20%)
      top: 8px
      left: 10px
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05)
      transform: rotate(-1deg)
      -webkit-transform: rotate(-1deg)
      border: 1px solid tint-color($primary, 5%)
      transition: all 200ms ease
      &:hover
        transform: rotate(-7deg) translateY(-15%)
      &::before
        content: ''
        position: absolute
        top: 9%
        left: 0
        width: 100%
        height: 100%
        line-height: 40px
        opacity: 1
        font-size: 20px
        text-transform: capitalize
        font-weight: bold
        -webkit-font-smoothing: antialiased
        color: $white
        text-shadow: 1px 1px 1px rgba(0,0,0,0.5)
  &:global(.archived)
    &:before
      background-color: $warning !important
    &:after
      background-color: $warning !important
