@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '~bootstrap/scss/mixins'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.entityNav
  \:global(button)
    @include media-breakpoint-down(xl)
      width: 30%
      text-align: left
      display: block
      margin-bottom: 10px
    @include media-breakpoint-down(lg)
      width: 96%
      margin-bottom: 10px
  \:global(.dropdown)
    \:global(.dropdown-toggle)
      span
        color: inherit
