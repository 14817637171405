@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.project
  width: 100%
  display: grid
  grid: "nav header" min-content "nav module-host" 1fr/min-content 1fr
  min-height: 100vh
  .projectContent
    grid-area: module-host
    min-width: 0
    position: relative
    overflow: auto
    z-index: 0

  @media screen and (max-width: $mobileActive)
    display: block
