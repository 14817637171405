@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.formBox
  border-radius: $border-radius-desktop-sm
  background-color: tint-color($primary, 85%)
  @include dropShadowLg()
  position: relative
  margin-right: 10px
  border: 0
  .formBoxClose
    position: absolute
    top: -10px
    right: -10px
    width: 38px
    height: 38px
    border: 1px solid $gray-300
    border-radius: 50%
    background-color: $white
    @include dropShadowSm()
    transition: 0.5s
    &:hover
      cursor: pointer
      border: 1px solid $red
      @include dropShadowLg()
      &:before
        background-color: shade-color($red, 13%)
    &:before
      content: "X"
      position: absolute
      border-radius: 50%
      width: 30px
      height: 30px
      top: 3px
      left: 3px
      text-align: center
      font-weight: $font-weight-bold
      background-color: $red
      color: $white
      font-size: 20px
      transition: 0.5s
  .formBoxHeader
    padding: 10px
    color: white !important
    border-top-left-radius: $border-radius-desktop-sm
    border-top-right-radius: $border-radius-desktop-sm
    background-color: $fb-blue-dark
    i
      color: $white
      margin-right: 10px
  .formBoxBody
    padding: 20px

  @media screen and (max-width: $mobileActive)
    margin-right: -15px
    margin-left: -15px
    box-shadow: none
    border-radius: 0 !important
    .formBoxHeader
      border-top-left-radius: 0
      border-top-right-radius: 0
