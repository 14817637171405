@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.listGroup
  .listGroupItem
    border: 0
    background-color: transparent
    transition: 0.1s
    a:hover
      color: $primary
      text-decoration: none
    &:global(.active)
      border-radius: $border-radius-desktop-lg
      background-color: $primary
      @include dropShadowSm()
      a
        color: $white
        &:hover
          color: $white
          text-decoration: none

