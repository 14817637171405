
.selectionLanding
  padding-top: 50px
  padding-bottom: 50px
  .selectionsFormBody
    text-align: center
    i
      font-size: 90px
      margin: 0
      padding: 0
      line-height: 50px
