@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'

.yoPillsNavLink
  color: $gray-700
  &:global(.nav-link)
    &:global(.active)
      background-color: $primary
      color: $white
      border-radius: $border-radius-desktop-lg
      @include dropShadowSm
