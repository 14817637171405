@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'

.folder
  .categoryToggle
    background: transparent
    box-shadow: none
    border: 0
    padding: 0
    color: $gray-500
    margin-left: 3px
    margin-right: 16px
    &:focus
      box-shadow: none
    &:hover
      background: transparent
      color: $gray-500

  .categoryLink
    color: $gray-500
    &:global(.active)
      color: $white

  &:before
    content: "" !important
    display: none !important
