@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.form
  margin-right: 20px
  .formHeader
    padding: 10px 15px
  .formBody
    ul
      padding: 0 10px
      margin: 0
      li
        padding: 0
        margin: 0
    .spinner
      position: relative !important
      height: auto !important

  @media screen and (max-width: $mobileActive)
    margin: 20px 0px 0px 0px
