@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'

.gameEnvSelector
  \:global(.btn-success)
    background-color: $green
    color: $gray-800
    &:hover, &:focus, &:active
      background-color: shade-color($green, 10%)
  \:global(.btn-warning)
    background-color: $yellow
    color: $gray-800
    &:hover, &:focus, &:active
      background-color: shade-color($yellow, 10%)
  \:global(.btn-danger)
    background-color: $red
    color: $white
    &:hover, &:focus, &:active
      background-color: shade-color($red, 10%)
  \:global(.btn-info)
    background-color: $cyan
    color: $white
    &:hover, &:focus, &:active
      background-color: shade-color($cyan, 10%)
