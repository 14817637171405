@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.diffCompareTab
  position: relative
  \:global(.container)
    position: relative
  \:global(.nav-pills)
    position: absolute
    width: auto
    top: -75px
    right: 20px
    margin-right: 0
    margin-left: auto
    display: inline-flex
    flex-direction: row
    justify-content: flex-end
    margin-bottom: 20px
    @media screen and (max-width: $mobileActive)
      position: initial
      top: 0
      margin: 0 auto 20px
    \:global(.nav-link)
      background-color: $white
      border: 1px solid $gray-200
      color: $gray-800
      margin-left: 5px
      align-content: center
      padding-top: 7px
      padding-bottom: 7px
      transition: 0.5s
      &:hover
        background-color: shade-color($white, 10%)
        border-color: $gray-400

      &:global(.active)
        background-color: $primary
        color: $white
        border: 0
        \:global(.bg-primary)
          background-color: $white !important
          color: $primary
          margin-bottom: 5px

