@import '../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../node_modules/bootstrap/scss/functions'
@import '../../node_modules/bootstrap/scss/variables'

.yoTabs
  max-width: 1370px
  margin: 0 auto 30px
  border: none
  padding: 0 15px
  \:global(.nav-item)
    margin-right: 20px !important
    &:last-child
      margin-right: 0 !important
    \:global(.nav-link)
      color: $gray-700
      background-color: transparent !important
      border: none
      border-bottom: 5px solid transparent
      padding: 10px 0
      &:global(.active)
        border-bottom: 5px solid $primary
      &:global(.active), &:hover
        background-color: transparent !important
        color: $primary
        span
          color: $primary
          \:global(.badge)
            background-color: $primary !important
            color: $white
  \:global(.tab-content)
    padding: 0 !important
