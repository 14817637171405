@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '~bootstrap/scss/mixins'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.searchResult
  background-color: $gray-200
  padding: 10px
  margin-bottom: 3px
  &.success
    background-color: $green
  &.warning
    background-color: $yellow
  &.danger
    background-color: $red
  &.info
    background-color: $cyan





