@import '../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../node_modules/bootstrap/scss/functions'
@import '../../node_modules/bootstrap/scss/variables'

.panel
  display: flex
  flex-direction: column
  padding: 0 100px
  justify-content: center
  justify-items: center
  align-content: center
  align-items: center
  .panelContent
    display: flex
    flex-direction: column
    justify-content: center
    justify-items: center
    align-content: center
    align-items: center
    padding: 0 40px
    i
      font-size: 50px
      color: $gray-400
    .panelTitle
      font-weight: 400
      font-size: 30px
      color: $gray-400
