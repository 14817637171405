@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.selectionButtons
  text-align: right
  background-color: $primary
  border-top-left-radius: $border-radius-desktop-lg
  border-top-right-radius: $border-radius-desktop-lg
  padding: 10px 20px
  \:global(.btn)
    margin-left: 10px
  @media screen and (max-width: $mobileActive)
    border: 0 !important
    box-shadow: none !important
    margin-top: 30px
    padding: 2px 10px
    \:global(.btn)
      display: block
      margin: 10px 0
      width: 100%
