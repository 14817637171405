@import '../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../node_modules/bootstrap/scss/functions'
@import '../../node_modules/bootstrap/scss/variables'

.tabs
  display: flex
  flex-direction: column
  justify-content: flex-end
  \:global(.nav-item)
    &:global(.nav-link)
      &:global(.active), &:hover
        color: $white
        span
          color: $primary

