@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'


.publisherNav
  background-color: $dark-nav

  @include dropShadowLg()
  \:global(.navbar-brand)
    color: $white
    font-weight: $font-weight-bold
    text-transform: uppercase
    &:hover
      color: $primary

    span
      display: block
      font-size: 14px
      line-height: 0
      color: $white
  \:global(.navbar-nav)
    \:global(.nav-link)
      background: transparent
      border: 0
      color: $gray-500
      i
        width: 50px
        text-align: center
      span
        color: $gray-500
        width: 100%
        display: inline-block
        &:hover
          color: $primary
        a
          color: inherit
          &:hover
            color: $primary
            text-decoration: none
          &:global(.active)
            color: $white
      &:global(.active)
        color: $white !important
        span
          color: $white !important
  \:global(.dropdown-menu)
    background-color: $dark-nav
    border-top-left-radius: 0
    border-top-right-radius: 0
    border-top: 0
    margin-top: -3px
    padding-top: 10px
    padding-right: 10px
    \:global(.dropdown-divider)
      border-color: $gray-600
      background-color: $gray-600
    \:global(.dropdown-item)
      color: $gray-500
      &:global(.add-item-link)
        color: $green
      &:hover
        background: $primary
        color: $white
      &:global(.active)
        color: $white !important
      &:global(.sub-category)
        padding-left: 80px !important
        position: relative
        &:before
          content: "-"
          position: absolute
          left: 70px

  @media screen and (min-width: $mobileActive)
    flex-direction: column
    align-items: flex-start
    width: 330px
    grid-area: nav
    height: 100vh
    position: sticky
    top: 0
    padding: 0 0 50px 0
    box-shadow: none !important
    background-color: $fb-blue-darkest
    scroll-margin-top: 0
    overflow: visible
    z-index: 1

    \:global(.container)
      flex-direction: column
      align-items: flex-start
      padding: 0
      \:global(.navbar-brand)
        width: 100%
        background-color: $fb-blue-dark
        padding: 10px 10px 0 27px !important
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between

        img
          margin-right: 7px
      .publisherNavInner
        width: 100%
        align-items: flex-start
        \:global(.navbar-nav)
          width: 100%
          flex-direction: column
          .scrollWrapper
            overflow-y: scroll
            height: 85vh
            padding-bottom: 50px
            &::-webkit-scrollbar
              width: 8px
            &::-webkit-scrollbar-track
              background: $fb-blue-darkest
            &::-webkit-scrollbar-thumb
              background: shade-color($primary, 25%)
            &::-webkit-scrollbar-thumb:hover
              background: shade-color($red, 30%)
            \:global(.nav-item)
              margin: 0
              border-bottom: 1px solid shade-color($fb-blue-darkest, 10%)
              box-shadow: rgb(42, 72, 101) 0px -1px 0px 0px inset
              padding-bottom: 0
              transition: background-color .2s cubic-bezier(0.4, 0, 0.2, 1),padding .3s ease

              \:global(.nav-link)
                padding-left: 10px
                padding-right: 10px
                margin: 10px 0
                border-right: 8px solid $fb-blue-darkest
                &:hover
                  color: $primary
                  text-decoration: none
                &:global(.active)
                  background-color: $fb-blue-dark
                  border-right: 8px solid $primary
                  margin-top: 0
                  margin-bottom: 0
                  padding-top: 18px
                  padding-bottom: 18px
              &:global(.active)
                \:global(.nav-link)
                  background-color: $fb-blue-dark
                  border-right: 8px solid $primary
                  margin-top: 0
                  margin-bottom: 0
                  padding-top: 18px
                  padding-bottom: 18px
                  &:global(.dropdown-toggle)
                    &:after
                      margin-left: -10px
          \:global(.dropdown-menu)
            position: relative
            left: 0 !important
            right: auto !important
            width: 100%
            border-bottom: 0
            background-color: $fb-blue-dark
            white-space: nowrap
            \:global(.dropdown-item)
              padding-left: 50px
              overflow: hidden
              padding-top: 5px
              padding-bottom: 5px
              border-right: 8px solid $fb-blue-dark
              &:global(.active)
                background-color: transparent
                border-right: 8px solid $primary

  @media screen and (max-width: $mobileActive)
    \:global(.navbar-brand)
      img
        max-height: 40px
    \:global(.dropdown-menu)
      border: 0
      margin: 0 !important
      padding: 0
      \:global(.dropdown-item)
        padding-left: 5px
        padding-right: 0
        &:global(.active)
          background-color: transparent
      \:global(.dropdown-divider)
        display: none
