@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

label.required
  position: relative
  &:before
    content: "*"
    position: absolute
    top: -2px
    right: -15px
    color: $red
    font-size: 24px
    margin: 0
    padding: 0
    z-index: 0
