@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'


.categoryTopLevelNav
  display: flex
  flex-flow: row nowrap
  justify-content: space-between
  align-items: center

  .categoryTopLevelLink
    color: $gray-500
    &:hover
      color: $primary
      text-decoration: none
    &:global(.active)
      color: $white

  .categoryTopLevelToggle
    background: transparent
    box-shadow: none
    border: 0
    padding: 0
    &:focus
      box-shadow: none

.categoryList
  overflow-x: hidden
  ul
    margin-left: 6px
    padding-left: 20px
    border-left: 1px solid $gray-700

    &.categoryListTop
      border: none
      margin-left: 0
      margin-bottom: 0

    li
      list-style: none
      margin: 3px 0
      &:first-child
        padding: 5px 0 0 0
      &:last-child
        margin: 0 0 15px 0
      &:before
        margin-right: 5px
        height: 20px
        vertical-align: top
        width: 20px
        background-repeat: no-repeat
        display: inline-block
        content: "\f15c"
        font-style: normal
        font-variant: normal
        text-rendering: auto
        -webkit-font-smoothing: antialiased
        font-family: "Font Awesome 5 Free"
        font-weight: 900
        color: $gray-500
        opacity: 1




