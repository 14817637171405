@import '../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../node_modules/bootstrap/scss/functions'
@import '../../node_modules/bootstrap/scss/variables'

.status
  text-transform: uppercase
  font-weight: $font-weight-bold
  &:global(.published)
    color: $success
  &:global(.draft)
    color: $info
  &:global(.stopped)
    color: $danger
  &:global(.archived)
    color: $gray-600

