@import '../../../ui-styles/src/lib/sass/_yo-mixins.sass'
@import '../../../ui-styles/src/lib/sass/yo-variables'
@import '../../../../node_modules/bootstrap/scss/_functions.scss'
@import '../../../../node_modules/bootstrap/scss/_variables.scss'
@import '../../../ui-styles/src/lib/sass/yo-breakpoints'

.infoBox
  @include dropShadowSm()
  background-color: $white
  border: 1px solid $gray-100
  //margin: 30px 0
  padding: 10px 20px
  width: 100%
  border-radius: $border-radius-desktop-lg
  @media screen and (max-width: $mobileActive)
    box-shadow: none !important
    margin: 5px 0
    padding: 10px 0
    background-color: transparent !important
    border: 0

  .header
    display: block
    font-weight: $font-weight-bold
    i
      margin-right: 10px
      color: $primary
      font-size: 18px
  .body
    font-size: 18px
    font-weight: $font-weight-lighter
    max-height: 25px
    overflow: hidden
    display: block
    &.draft
      color: $info
      text-transform: uppercase
    &.live
      color: $green
      text-transform: uppercase
    &.stopped
      color: $red
      text-transform: uppercase

