@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '~bootstrap/scss/mixins'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.contentBox
  background-color: $white
  background-clip: border-box
  border: 1px solid rgba(0, 0, 0, 0.125)
  border-radius: $border-radius-desktop-lg
  box-shadow: none
  margin-bottom: 30px

  // START listView
  \:global(.listView) &
    margin-bottom: 0
    border-radius: 0
    border-bottom: none
    border-top: none
    .contentBoxHeader
      border-radius: 0 !important
    .contentBoxNavBar
      border-radius: 0 !important
      padding-top: 0
      padding-bottom: 0
      background-color: $gray-600
      span
        color: $gray-100 !important
        font-size: 16px
    .contentBoxBody
      padding: 0
      \:global(.table-responsive)
        margin-top: 0
      \:global(table)
        margin-bottom: 0
        border: none !important
        border-bottom-left-radius: 0 !important
      \:global(thead)
        height: auto !important
        \:global(tr)
          height: auto !important
      \:global(tbody)
        border-bottom-left-radius: 0 !important
      \:global(th)
        border-radius: 0 !important
        border-bottom: 0
        padding: 0 12px
        line-height: initial
        background-color: $gray-400
        border-color: $gray-400
        color: $gray-700
        width: 25%
        overflow-x: hidden
      \:global(tr:last-child)
        border-bottom: none !important
      \:global(tr)
        border-top: none !important
        border-bottom: 1px solid $gray-400
        &:hover
          border-bottom-left-radius: 0 !important
      \:global(tr:last-child)
        border-bottom: none
        \:global(td:first-child)
          border-bottom-left-radius: 0
        \:global(td:last-child)
          border-bottom-right-radius: 0
        \:global(td)
          border-bottom: none
      \:global(td)
        border-radius: 0
        width: 25%
        overflow-x: hidden
  // END listView


  @media screen and (max-width: $mobileActive)
    border: 0 !important
    border-radius: 0 !important
    margin-left: -15px
    margin-right: -15px
  .contentBoxHeader
    nav
      @include media-breakpoint-down(lg)
        \:global(.navbar-collapse)
          padding-top: 20px
          \:global(.btn-group)
            width: 100%
            align-content: space-evenly
            align-items: baseline
            justify-content: space-between
            flex-direction: row
          \:global(.dropdown-menu)
            z-index: 10000
          \:global(.btn)
              border: 0
              background-color: transparent
              padding: 0
              color: $white
              margin: 10px 0
              display: block
          \:global(.btn-group)
            \:global(.btn)
              border: initial
              background-color: $white
              padding: 2px 10px
              color: initial
              display: initial
              &:global(.btn-danger)
                background-color: $danger

      \:global(.navbar-text)
        color: $white !important
        font-size: 14px
      i
        margin-right: 10px
        font-size: 18px
      span
        &:global(.badge)
          font-weight: $font-weight-bold
          padding: 8px
          margin-left: 3px
          @include dropShadowSm()

  .contentBoxBody
    padding: 20px 15px
    @media screen and (max-width: $mobileActive)
      padding-top: 0
      padding-bottom: 0
      \:global(label)
        margin-top: 20px
      //&:first-child
      //  margin-top: 0



  .contentBoxNavBar
    background-color: $primary
    border-top-left-radius: $border-radius-desktop-lg
    border-top-right-radius: $border-radius-desktop-lg
    button, :global(.btn)
      margin-left: 10px
    \:global(.btn-group)
      button, :global(.btn)
        margin-left: -1px
    &:global(.archived)
      background-color: $warning
      \:global(.navbar-text)
        span, i, svg
          color: $gray-900 !important
    @media screen and (max-width: $mobileActive)
      \:global(.navbar-text)
        \:global(.text)
          display: none
      button
        \:global(.icon-with-text)
          i
            margin: 0
    @media print
      &:global(.navbar)
        display: block
      \:global(.navbar-toggler)
        display: none




