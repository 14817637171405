@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '~bootstrap/scss/mixins'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.calendarKey
  margin-top: 162px

  \:global(.list-group-item)
    border-radius: 0
    padding-right: 25px
    padding-left: 10px
    background-color: transparent
    border-top-color: transparent
    border-left-color: transparent
    border-bottom-color: $gray-200
    border-bottom-width: 2px
    border-right-width: 5px
    //border-right-color: transparent
    color: $gray-900
    position: relative
    margin-bottom: 5px
    &:hover
      cursor: pointer
    &:before
      display: inline-block
      font-style: normal
      font-variant: normal
      text-rendering: auto
      -webkit-font-smoothing: antialiased
      font-family: "Font Awesome 5 Free"
      font-weight: 900
      content: "\f070"
      font-size: 14px
      position: absolute
      right: 8px
      top: 12px
      left: auto
      color: $gray-600

    &:global(.active)
      background-color: $white
      &:before
        content: "\f06e"
        color: $primary

