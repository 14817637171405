@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.kindCard
  \:global(.card-body)
    padding: 0!important
    @media screen and (min-width: $mobileActive)
      height: 160px !important

