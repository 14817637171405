@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '~bootstrap/scss/mixins'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'

.rbc-time-view
  .rbc-allday-cell
    max-height: 200px
    overflow: scroll

.rbc-overlay
  .rbc-overlay-header
    background-color: $dark-nav
    color: $white
    font-weight: $font-weight-bold
    margin-bottom: 10px
  .rbc-event
    &.rbc-selected
      .rbc-event-content
        color: $white


#entitiesCalendar
  .rbc-month-view
    background-color: $white
  .rbc-agenda-table
    border: none !important
    th
      padding-left: 10px
    td
      color: inherit !important
      &:first-child
        border-radius: 0 !important
      span
        color: inherit !important

  .rbc-toolbar
    margin-bottom: 40px
    @media screen and (max-width: $mobileActive)
      flex-direction: column
    .rbc-toolbar-label
      font-weight: $font-weight-bold
      font-size: 1.5rem
    .rbc-btn-group
      @include dropShadowSm()

  .rbc-header
    background-color: $dark-nav
    color: $white !important
    span
      color: $white !important

  .rbc-event
    .rbc-event-content
      color: inherit
    &.localTime
      .rbc-event-content
        position: relative
        padding-left: 24px
        &:before
          font-family: "Font Awesome 5 Free"
          font-weight: 900
          content: "\f017"
          position: absolute
          left: 0
    &.startTimeOnly
      .rbc-event-content
        position: relative
        padding-left: 24px
        &:before
          font-family: "Font Awesome 5 Free"
          font-weight: 900
          content: "\f04b"
          position: absolute
          left: 0
    &.endTimeOnly
      .rbc-event-content
        position: relative
        padding-left: 24px
        &:before
          font-family: "Font Awesome 5 Free"
          font-weight: 900
          content: "\f04d"
          position: absolute
          left: 0

  &.success
    .rbc-toolbar
      .rbc-toolbar-label
        color: $success
      .rbc-btn-group
        button
          border-color: shade-color($success, 30%)
          &:hover
            background-color: shade-color($success, 20%)
            color: $gray-200
          &.rbc-active
            background-color: $success
            color: $white


  &.warning
    .rbc-toolbar
      .rbc-toolbar-label
        color: $warning
      .rbc-btn-group
        button
          border-color: shade-color($warning, 20%)
          &:hover
            background-color: shade-color($warning, 10%)
            color: $gray-800
          &.rbc-active
            background-color: $warning
            color: $gray-900

  &.danger
    .rbc-toolbar
      .rbc-toolbar-label
        color: $danger
      .rbc-btn-group
        button
          border-color: shade-color($danger, 20%)
          &:hover
            background-color: shade-color($danger, 10%)
            color: $gray-200
          &.rbc-active
            background-color: $danger
            color: $white

  &.info
    .rbc-toolbar
      .rbc-toolbar-label
        color: $info
      .rbc-btn-group
        button
          border-color: shade-color($info, 20%)
          &:hover
            background-color: shade-color($info, 10%)
            color: $gray-200
          &.rbc-active
            background-color: $info
            color: $white

  #statusSelector
    text-transform: capitalize
    display: inline-block
    i
      font-size: 0.8rem
      margin-right: 6px
      &.published
        color: $green
      &.draft
        color: $info
      &.archived
        color: $orange
      &.stopped
        color: $red
