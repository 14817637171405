@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.yoCard
  box-shadow: none
  transition: 0.2s
  text-align: center
  border-radius: $border-radius-desktop-lg
  @include dropShadowSm()
  &:hover
    @include dropShadowLg
    @media screen and (max-width: $mobileActive)
      background-color: $primary
      \:global(.card-title)
        color: $white !important
      \:global(.card-text)
        color: $gray-300
      \:global(.card-settings)
        a
          i
            color: $white !important
  \:global(.card-header)
    &:global(.live)
      color: $green
      text-transform: uppercase
    &:global(.archived)
      color: $orange
      text-transform: uppercase
  \:global(.card-body)
    align-items: center
    justify-content: center
    display: flex
    flex-direction: column
    padding: 10px
    overflow: hidden
    height: 200px
    @media screen and (max-width: $mobileActive)
      height: inherit
      padding: 0
    a
      align-items: center
      justify-content: center
      display: flex
      flex-direction: column
      padding: 10px
      overflow: hidden
      width: 100%
      height: 100%
      &:hover
        \:global(.card-title)
          color: $danger
      @media screen and (max-width: $mobileActive)
        align-items: flex-start
        justify-content: space-between
        justify-items: center
        align-content: center
        display: flex
        flex-direction: row
        padding: 10px 40px 10px 40px
        overflow: hidden
        position: relative
        height: 100%

    &:after
      @media screen and (max-width: $mobileActive)
        font-family: "Font Awesome 5 Free"
        font-weight: 900
        content: "\f105"
        position: absolute
        right: 0
        top: 0
        font-size: 2rem
        background-color: $gray-200
        border-top-right-radius: 20px
        border-bottom-right-radius: 20px
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        padding: 0 10px



  &:global(.archived)
    background-color: $warning

    \:global(.card-header)
      display: none
    \:global(.card-body)
      \:global(.card-title)
        color: $black
        &:after
          border-color: $black
      \:global(.card-text)
        color: $black
      &:hover
        \:global(.card-title)
          color: $gray-200
    \:global(.card-footer)
      background-color: shade-color($orange, 15%)
      color: $black
      &:hover
        background-color: $black
        color: $gray-300

  \:global(.card-settings)
    text-align: right
    position: absolute
    top: 10px
    right: 10px
    &:hover
      color: $primary
    @media screen and (max-width: $mobileActive)
      text-align: left
      position: absolute
      top: 10px
      left: 10px
      z-index: 100
      width: 20px
      a
        i
          &:hover
            color: $red !important
  \:global(.card-title)
    margin: 0 0 20px !important
    font-weight: 400
    position: relative
    color: $primary
    word-break: break-word
    @media screen and (max-width: $mobileActive)
      font-size: 1.1rem
      margin: 0 !important
      color: initial
    &:after
      position: absolute
      content: ""
      width: 50px
      border-bottom: 1px solid $gray-500
      bottom: -10px
      left: 0
      right: 0
      margin: 0 auto
      max-width: 100%
      @media screen and (max-width: $mobileActive)
        content: none
    &:hover
      color: $primary
  \:global(.card-text)
    margin-bottom: 10px
    font-size: 0.8rem
    padding-top: 5px
    padding-bottom: 8px
    overflow: hidden
    text-overflow: ellipsis
    @media screen and (max-width: $mobileActive)
      display: none
    &:global(.status)
      text-transform: uppercase
      &:global(.live)
        i
          color: $red
      &:global(.archived)
        i
          color: $black

  \:global(.card-footer)
    padding: 5px !important
    color: $gray-200
    margin: 0
    border-bottom-right-radius: $border-radius-desktop-lg
    border-bottom-left-radius: $border-radius-desktop-lg
    background-color: #498edb
    &:hover
      background-color: $danger
    a
      color: $gray-200
      text-decoration: none
      display: block
