@import '../../../../../../libs/ui-styles/src/lib/sass/yo-variables'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-mixins'
@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import '~bootstrap/scss/mixins'
@import '../../../../../../libs/ui-styles/src/lib/sass/yo-breakpoints'

.searchBox
  position: relative
  .searchBoxPrepend
    span
      background-color: $fb-blue-dark
      color: $gray-200

.searchResults
  position: absolute
  top: 100%
  left: 0
  right: auto
  min-width: 500px
  background-color: $white
  box-shadow: $box-shadow-lg
  border-radius: $border-radius-desktop-sm
  margin-top: 5px
  .dropdownMenu
    background-color: transparent !important
    border: 0 !important
    padding: 5px
    .menuItem
      border: 0
